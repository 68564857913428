




























































































































































import Vue from 'vue'
import IntermediateHeader from '@/components/Brand/IntermediateHeader.vue'
import SubscriptionPacks from '@/components/Brand/SubscriptionPacks.vue'
import Component from 'vue-class-component'

@Component({
  components: {
    IntermediateHeader,
    SubscriptionPacks
  }
})
export default class FirstSubscription extends Vue { }
