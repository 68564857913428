var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "header-space" }),
    _c("div", { staticClass: "header" }, [
      _c("img", {
        staticClass: "header-logo",
        attrs: { src: "/img/Dashboard/HeaderLogo.png" }
      }),
      _c("div", { staticClass: "profile" }, [
        _c("img", {
          attrs: {
            src:
              _vm.$store.state.userData.image ||
              _vm.$common.getDefaultImage("brand")
          },
          on: {
            error: function($event) {
              return _vm.$common.changeDefaultImage($event, "brand")
            }
          }
        }),
        _c("span", [_vm._v(_vm._s(_vm.$store.state.userData.name))])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }